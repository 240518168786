
@import "../../node_modules/bootstrap/scss/bootstrap";

/* all dashboard styles */
@import "rtl/shared/global";
@import "rtl/shared/table";
@import "rtl/shared/title";
@import "rtl/shared/navigation";
@import "rtl/shared/modal";
@import "rtl/shared/loader";
@import "rtl/shared/form";
@import "rtl/shared/search";
@import "rtl/shared/cards";

@import "rtl/login";
@import "rtl/analytics";
@import "rtl/register";
@import "rtl/tabbed_form";
@import "rtl/features";
