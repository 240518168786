// shared
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none !important;
}
.img-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
}

// table
.table-container {
    .ant-spin-nested-loading {
        table {
            width: 100% !important;
            table-layout: initial !important;
            .place-details-text {
                text-align: right !important;
            }
            .ant-table-thead {
                .ant-table-cell {
                    background-color: #f5f5f5 !important;
                    color: #5a47ce !important;
                    font-size: 16px !important;
                    border-bottom-color: transparent;
                    border-top-color: transparent;
                }
                .ant-table-column-sorter-up.active,
                .ant-table-column-sorter-down.active {
                    color: #5a47ce !important;
                }
            }
            .ant-table-thead {
                .ant-table-cell {
                    text-align: center;
                }
            }
            .service-img-container{
                text-align: right !important;
            }
            .place-details-actions{
                text-align: left !important;
            }
            td {
                color: #707070;
                text-align: center !important;
                .details,
                .edit-icon,
                .reviews_link {
                    color: #5a47ce;
                    cursor: pointer;
                }
                .review_star {
                    color: #f9d608;
                }
                .delete-icon {
                    color: #e4e4e4;
                    cursor: pointer;
                    transition: all 0.13s ease-in-out;
                    &:hover,
                    &:active {
                        color: rgba(235, 33, 33, 0.59);
                    }
                }
                .unblock-icon {
                    color: rgba(235, 33, 33, 0.59);
                }
                .react-icon {
                    color: #a9a9a9;
                }
                // for long text
                .display-text-group {
                    max-width: 200px;
                    max-height: 74px;
                    overflow: hidden;
                }
                // for features
                .reply {
                    color: #0ad23f;
                    font-size: 16px;
                }
                .no-reply {
                    color: #fb0606;
                    font-size: 16px;
                }
                vertical-align: middle !important;
                .pending {
                    color: #ffee01;
                    font-weight: bold;
                }
                .underway {
                    color: #0ad23f;
                    font-weight: bold;
                }
                .rejected {
                    color: #fb0606;
                    font-weight: bold;
                }
            }
        }

        // default pagination
        ul {
            display: none !important;
        }

    }
    .btn-container {
        margin: 0 !important;
    }
}

// icons

/* stars */
.review_star {
    color: #f9d608;
    border-color: #f9d608;
}
.empty_star {
    color: #f9d608;
}
.fav_icon{
    color: #e25059;
    border-color: #e25059;
}
// pagnation
.ant-pagination-options {
    display: none;
}
.ant-pagination-item-link {
    .anticon {
        color: #5a47ce !important;
    }
}
.ant-pagination-item {
    color: #000 !important;
    height: 34px !important;
    width: 34px !important;
    line-height: 34px !important;
}
.ant-pagination-item-active {
    color: #5a47ce !important;
    border-color: #5a47ce !important;
    border-radius: 6px !important;
}
.ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #5a47ce;
}
.page_num {
    height: 34px !important;
    width: 34px !important;
    line-height: 34px !important;
    border: 1px solid #5a47ce;
    border-radius: 6px;
    color: #5a47ce;
}

/* media query */
// md screen
@media only screen and (min-width: 768px) {
    /* read more text */
    .display-text-group {
        max-width: 200px !important;
        max-height: 24px !important;
        line-height: 24px !important;
    }
}

// lg screen
@media only screen and (min-width: 1200px) {
    /* read more text */
    .display-text-group {
        max-width: 400px !important;
    }
}
