// register page
.rc-virtual-list-holder-inner {
    // change direction of select box options
    text-align: right !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
    height: 0 !important;
}
.register {
    padding: 5rem auto;
    .ant-form {
        margin-top: 5rem;
        // logo
        .logo-label {
            color: #707070;
            font-size: 16px;
            font-weight: bold;
        }
        .ant-upload-picture-card-wrapper {
            display: flex !important;
            width: initial;
        }
    }
}
