/*--------------------- navigation items page ---------------------- */
/*sidebar*/
.ant-layout-sider {
    background-color: #0f0023 !important;
    .ant-menu{
        margin-top: 3rem;
    } 
    .ant-menu.ant-menu-dark {
        background-color: #0f0023 !important;
    }

    .ant-layout-sider-children {
        // badge
        .badge{
            background-color: #FC3F3F;
            padding: 0.25rem 0.75rem;
        }
        // icons
        .anticon {
            float: right;
            padding: 1rem;
            svg {
                color: #fff;
            }
        }
        // logo
        .logo {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-top: 1rem;
            img {
                width: 100%;
                border-radius: 50%;
            }
        }
        // selected li
        .ant-menu-item {
            transition: all 0.13s ease-in-out !important;
        }
        .ant-menu-item-selected {
            background-color: rgba(255, 255, 255, 0.3) !important;
            border-right: 4px solid #fff !important;
        }
    }
}

/* header */
.ant-layout-header {
    // notification 
    .notification-icon{
        color: #707070;
        cursor: pointer;
    }
    
    // dropdown
    .anticon-caret-down {
        svg {
            color: #000 !important;
        }
    }
    .btn {
        &:focus {
            box-shadow: none !important;
        }
    }
    .dropdown-menu {
        padding: 0;
        ul {
            padding: 0;
            margin: 0;
            a {
                color: #000;
                text-align: right;
                padding: 0 1rem;
            }
        }
    }
}

/* mobile sidebar */
.close-mobile-sidebar {
    transform: translateX(0);
    transition: all 0.2s ease-in-out;
}
/* media query */
@media only screen and (max-width: 991.9px) {
    /* backdrop */
    .Backdrop {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .show-mobile-sidebar {
        transition: all 0.2s ease-in-out;
        transform: translateX(100%);
    }

    .ant-layout-sider {
        position: fixed !important;
        height: 100%;
        z-index: 1000;
    }
}
