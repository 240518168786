/* --------------------------------------- loader styling ------------------------------ */

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
        background: #5a47ce;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        float: left;
        top: 40px;
        position: relative;
        margin-left: 10px;
        &:nth-child(2),
        &:nth-child(4) {
            animation: animatetwo 1s linear infinite;
            top: -40px;
        }
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5) {
            animation: animateone 1s linear infinite;
            top: 40px;
        }
    }
}

@keyframes animateone {
    0% {
        top: 40px;
        transform: scale(1);
    }
    50% {
        top: -40px;
        transform: scale(0.5);
    }
}

@keyframes animatetwo {
    0%,
    100% {
        top: -40px;
        transform: scale(0.5);
    }
    50% {
        top: 40px;
        transform: scale(1);
    }
}

/* styling spinner for fontawesome icon */
.spinner {
    animation: spin infinite 5s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
