@mixin btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;
}

@mixin btn {
    width: 120px;
    border-radius: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin primary-btn {
    margin-left: 8px;
    background-color: #5a47ce !important;
    border-color: #5a47ce !important;
    color: #fff !important;
    &:hover,
    &:active {
        background: #7e6fd8 !important;
        border-color: #7e6fd8 !important;
        color: #fff !important;
    }
}
@mixin secondary-btn {
    border-color: #5a47ce !important;
    color: #5a47ce !important;
    &:hover,
    &:active {
        text-decoration: none !important;
    }
}
@mixin ant-modal-close-x {
    position: relative !important;
    border-radius: 50% !important;
    background-color: #dadada !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 35px !important;
    color: #fff !important;
    margin: 0.5rem !important;
}
//styling antd modal
.ant-modal-wrap-rtl {
    direction: ltr;
    .ant-modal-content {
        padding: 16px;
    }
    .ant-modal-close-x {
        @include ant-modal-close-x;
    }
    .ant-modal-title {
        text-align: center !important;
    }
}

.ant-modal-body {
    .content {
        color: #a9a9a9 !important;
        font-size: 15px !important;
    }
    h6 {
        color: #5a47ce;
    }
    p {
        color: #707070;
    }
}
.ant-modal-footer {
    border-top: none !important;
    @include btn-container;
    .ant-form-item {
        margin-bottom: 0;
    }
    .ant-btn {
        @include btn;
    }
    .ant-btn-primary {
        @include primary-btn;
        margin-left: 8px !important;
        margin-right: unset !important;
    }
    .secondary-btn {
        @include secondary-btn;
    }
}

.confirm-footer{
    .ant-btn{
        width: 80px !important;
    }
}
@media (min-width: 576px) {
    .confirm-footer{
        .ant-btn{
            width: 120px !important;
        }
    }
}