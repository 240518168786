/*--------------------- add restaurant page ---------------------- */

// item data page form
.add_item {
    .time_picker {
        direction: ltr !important;
        .react-timerange-picker__wrapper {
            border: 1px solid #ced4da !important;
            border-radius: 0.25rem;
            .react-timerange-picker__inputGroup__input {
                &:focus {
                    color: #495057;
                    background-color: #fff;
                    border-color: #067def;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
            }
        }
    }

    // upload logo, place images, menu images
    #logo-input[type='file'],
    #place_input[type='file'],
    #menu_input[type='file'] {
        display: none;
    }

    // logo
    .logo {
        .upload_logo {
            background: transparent !important;
            border: 0;
            &:hover {
                background: transparent;
                border-color: transparent;
            }
        }
        .logo-container {
            position: relative;
            .delete-logo {
                position: absolute;
                top: -10px;
                left: -10px;
                border-radius: 50%;
            }
        }
    }

    // place images
    .multipleImgs,
    .phone_item,
    .adress_item {
        .btn-secondary {
            background: transparent !important;
            &:hover {
                background: transparent;
                border-color: transparent;
            }
        }
        .images-icon {
            font-size: 68px !important;
            color: #1b283a;
        }
        .update_remove {
            button {
                width: 100%;
                border-radius: 0 !important;
            }
        }
    }
}
// tree select box
.ant-select-tree-list-holder-inner {
    text-align: right;
}

/* ----------------------------- form --------------------------- */

//// description & places & menu
.places,
.menu,
.description {
    p {
        color: #707070;
        font-size: 16px;
        font-weight: bold;
    }
}
.ant-modal-wrap-rtl .ant-modal-close {
    /* preview image close btn */
    right: 0 !important;
    left: inital;
}

.description {
    textarea {
        border: none !important;
        background-color: #f5f5f5 !important;
        &:hover,
        &:focus {
            border: none !important;
            box-shadow: none !important;
            outline: 0;
        }
    }
}

/// menu & places
.img-hint {
    font-size: 12px !important;
    color: #de1e1e !important;
}
.err-msg {
    color: #ff4d4f;
    font-size: 12px;
}
.ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px !important;
    border: 2px solid #5a47ce !important;
    border-radius: 2px;
}

// branches
.ant-collapse-icon-position-right // change direction of down-arrow
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 16px !important;
    right: auto !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 26px !important;
    background-color: rgba(90, 71, 206, 0.08) !important;
    border: none !important;
    color: #989898 !important;
    font-size: 16px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    line-height: 4 !important;
}

#branches,
#time {
    .ant-collapse {
        border: none !important;
        background-color: transparent !important;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom: none !important;
    }
    .ant-collapse-content {
        border-top: none !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        border: 1px solid #dddddd !important;
    }
    .ant-select-rtl.ant-select-multiple.ant-select-allow-clear
        .ant-select-selector {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }
    .ant-form-item-label > label {
        color: #9e9e9e !important;
    }
}

// time
.time-tabs {
    .card-header{
        border: 0;
        button{
            //background-color: #0f0023 !important;
            color: #0f0023 !important;
            text-align: center;
            text-align: center;
            border: none;
            border-radius: 0;
            padding-top: 2rem;
            padding-bottom: 2rem;
            font-size: 16px;
        }

        .btn-outline-danger {
            border-radius: 0;
            //color:  #0f0023 !important;
            border-color:  #0f0023 !important;
            &:hover {
                color: rgba(255, 255, 255, 0.65) !important;
                background-color:  #0f0023 !important;
                border-color:  #0f0023 !important;
            }
        }
        .btn-outline-danger:not(:disabled):not(.disabled):active,
        .btn-outline-danger:not(:disabled):not(.disabled).active,
        .show > .btn-outline-danger.dropdown-toggle {
            background-color: #0f0023 !important;
            color: rgba(255, 255, 255, 0.65) !important;
        }
    }
    #time {
        .ant-form-item {
            display: inline-block !important;
        }
        .daily,
        .except,
        .closed,
        .day {
            color: #666666 !important;
            font-size: 16px !important;
            font-weight: bold !important;
        }
    
        // checkbox
        .ant-checkbox-wrapper {
            span {
                font-size: 16px !important;
                color: #666666 !important;
            }
        }
        .ant-checkbox {
            border: 1px solid #dddddd !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #7868d4 !important;
            border-color: #7868d4;
        }
        .ant-form input[type='checkbox']:focus {
            outline: none !important;
        }
        .ant-checkbox-checked::after {
            border-color: #7868d4 !important;
        }
    
        // time picker
        .from,
        .to {
            color: #5a47ce;
            font-weight: bold;
            font-size: 16px;
        }
        .am,
        .pm {
            color: #707070 !important;
            font-size: 11px !important;
        }
        .ant-picker-input > input {
            color: #707070 !important;
            font-size: 11px !important;
        }
        .ant-picker-rtl {
            height: 40px;
        }
        .ant-picker-focused {
            border-color: #7868d4 !important;
            border-right-width: 1px !important;
            outline: 0 !important;
            box-shadow: none !important;
        }
        .ant-picker:hover {
            border-color: #7868d4 !important;
        }
        // dynamic card
        .dynamic-card{
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.16);
            padding: 1rem;
            //h-offset v-offset blur spread color
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            margin-bottom: 1rem;
        }
        .add-btn {
            width: 30px !important;
            height: 30px !important;
            line-height: 15px;
            border: 1px solid #7868d4 !important;
            border-radius: 50%;
            background-color: #7868d4 !important;
            color: #fff !important;
            padding: 0.4px;
        }
        .remove-icon {
            background-color: #fff !important;
            cursor: pointer;
            border-radius: 50%;
            color: #dddddd !important;
        }
        .ant-space-item {
            width: 100% !important;
        }
        .day-select {
            .ant-row-rtl {
                width: 100% !important;
            }
        }
    }
}

.ant-form-item-has-error .ant-picker:focus{
    border-color: none !important;
    box-shadow: none !important
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus{
    box-shadow: none !important;
}
.ant-form-item-has-error .ant-picker{
    border-color: none !important;
}
.not-found{
    font-size: 15px;
    color: #707070;
}
