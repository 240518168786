// upload
.ant-upload.ant-upload-select-picture-card {
    border: 2px dashed #707070;
    background-color: #fff;
    &:hover {
        border: 2px dashed #707070;
    }
    .anticon svg {
        color: #5a47ce;
        font-size: 22px;
        font-weight: bolder;
    }
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
    top: 10%;
    background: #7868d4;
    width: 100%;
    display: flex;
    justify-content: space-around;
}
// styling all forms
.dashboard-forms {
    .ant-form {
        width: inherit;
        // input
        .ant-form-item-control-input {
            text-align: center;
            .ant-input {
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                border-radius: 6px !important;
                background-color: #f5f5f5 !important;
                border: none !important;
                outline: 0 !important;
                &:hover,
                &:focus,
                .ant-input-focused {
                    background-color: #f5f5f5 !important;
                    border: none !important;
                    outline: 0 !important;
                    box-shadow: 0 0 0 0 transparent;
                    outline: 0 !important;
                }
            }
        }
        // password & password confirmation input field
        .ant-input-affix-wrapper {
            padding: 0 11px;
            border-radius: 6px;
        }
        .ant-input-affix-wrapper > input.ant-input,
        .ant-input-affix-wrapper {
            background-color: #f5f5f5 !important;
            border-color: transparent !important;
            &:focus,
            .ant-input-affix-wrapper-focused,
            &:hover {
                background-color: #f5f5f5 !important;
                border-color: transparent !important;
                border-width: 0 !important;
                outline: 0 !important;
                box-shadow: 0 0 0 0 transparent !important;
            }
        }

        // selectbox
        .ant-select-selector {
            border-radius: 6px !important;
            background-color: #f5f5f5 !important;
            border-color: transparent !important;
            &:hover,
            &:focus,
            &:not(:focus) {
                border-color: none !important;
                box-shadow: 0 0 0 0 transparent !important;
            }
        }
        .ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 46px !important;
            display: flex;
            align-items: center;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector,
        .ant-input {
            border-radius: 6px !important;
            background-color: #f5f5f5 !important;
            border-color: none !important;
            &:focus,
            &:hover,
            &:not(:focus) .ant-input-focused {
                background-color: #f5f5f5 !important;
                border-color: none !important;
                outline: 0 !important;
                box-shadow: 0 0 0 0 transparent !important;
            }
        }

        .ant-input-affix-wrapper > input.ant-input,
        .ant-input-affix-wrapper {
            background-color: #f5f5f5 !important;
            border-color: transparent !important;
            &:focus,
            .ant-input-affix-wrapper-focused,
            &:hover {
                background-color: #f5f5f5 !important;
                border-color: transparent !important;
                border-width: 0;
                outline: 0;
                box-shadow: 0 0 0 0 transparent;
            }
        }

        // date picker
        .date-picker{
            .ant-form-item-control-input {
                height: 46px !important;
                border-radius: 6px !important;
                background-color: #f5f5f5 !important;
                border-color: transparent !important;
                &:hover,
                &:focus,
                &:not(:focus) {
                    border-color: none !important;
                    box-shadow: 0 0 0 0 transparent !important;
                }
                .ant-picker-input > input {
                    line-height: 40px;
                }
            }
        }
        /* --------------- error msgs ----------------*/
        .ant-form-item-has-error
            .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
            .ant-select-selector {
            // remove border at error
            border: none !important;
        }
        .ant-form-item-explain.ant-form-item-explain-error {
            color: #ff4d4f;
            font-size: 12px !important;
            margin-bottom: 0.5rem !important;
            text-align: right !important;
        }
    }
}

/* form feedback */
.invalid-text {
    display: inline;
    margin-top: 0.25rem;
    font-size: 12px;
    color: #ff4d4f;
}

// btns
.btn-container {
    margin-top: 5rem;
    margin-bottom: 5rem;
    .ant-btn {
        border-radius: 6px;
        width: 180px;
        height: 36px;
        line-height: 18px; /* = 1/2 height to center text vertically */
    }
    .ant-btn-primary {
        color: #fff !important;
        background: #5a47ce !important;
        border-color: #5a47ce !important;
        &:hover,
        &:focus {
            color: #fff !important;
            background: #7e6fd8 !important;
            border-color: #7e6fd8 !important;
        }
    }

    .secondary-btn {
        margin-right: 1rem;
        color: #5a47ce !important;
        background: #ffff !important;
        border-color: #5a47ce !important;
        &:hover,
        :focus {
            color: #5a47ce !important;
            background: #ffff !important;
            border-color: #5a47ce !important;
        }
    }
}

@media (max-width: 768px) {
    .btn-container {
        flex-direction: column;
    }
    .secondary-btn {
        margin-right: 0 !important;
    }
}

//label at form
.ant-form-item-label > label {
    color: #707070 !important;
    font-weight: medium !important;
}
.ant-form-item-label {
    /*label at right */
    text-align: right !important;
}
@media (max-width: 575px) {
    .ant-col-xs-24.ant-form-item-label {
        text-align: right !important;
    }
}

// remove star & : from label
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none !important;
}
