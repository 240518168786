// offer page
// add offer select pagination
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #5a47ce;
}

.ant-switch-checked {
    background-color: #5a47ce;
    &:focus {
        box-shadow: 0 0 0 2px #aca3e6;
    }
}

// offer details page
.offer-details {
    label,
    .logo-upload {
        color: #5a47ce !important;
        font-weight: bold;
    }
    .ant-picker {
        width: 100%;
    }
}

/* ------------------- notification page ----------------- */
//modal
.notification-modal {
    .ant-form {
        // logo
        .logo-label {
            text-align: right;
            color: #707070;
            font-size: 16px;
            font-weight: bold;
        }
        .ant-upload-picture-card-wrapper {
            display: flex !important;
            width: initial;
        }

        // btn
        .btn-container {
            margin-top: 1rem !important;
            margin-bottom: 1rem !important;
        }
    }
}

// media query
@media (min-width: 992px) {
    .offset-lg-3 {
        margin-right: 25%;
        margin-left: 0;
    }
}

@media (max-width: 767.9px) {
    .filter {
        margin-top: 1rem;
    }
    // notification search
    .feature-filter {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .offer-search{
        margin-bottom: 1rem !important;
    }
}
