/* global */
html {
    direction: rtl;
    text-align: right;
    outline: 0;
    --antd-wave-shadow-color: transparent !important; /* remove shadow */
}

.app-main {
    background-color: #fff !important;
}
// page scroll
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    transition: all 0.13s ease-in-out;
    background: #d4d3d3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #acabab;
}
// sroll in sidebar
.ps__rail-y {
    right: 266px !important;
}

// toast
.Toastify__toast {
    direction: rtl !important;
    .Toastify__toast-body {
        padding: 0 !important;
        text-align: right !important;
        font-size: 12px;
    }
    .Toastify__close-button {
        left: 9px;
        right: auto;
    }
}
.Toastify__progress-bar {
    right: 2%;
    left: auto;
    direction: rtl;
}

// dropdown hover bg color
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #e0ddf6;
}