/*--------------------- login page ---------------------- */
.login-form {
    height: 100vh;
    background-color: #fff;
    .ant-form {
        margin-top: 5rem;
        padding: 0 8rem !important;
    }
}
/* media query */
@media only screen and (max-width: 767.99px) {
    .login-form .ant-form {
        padding: 0 2rem !important;
    }
}
@media only screen and (min-width: 768px) {
    .login-form .ant-form {
        margin-top: 8rem !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .login-form .ant-form {
        padding: 0 5rem !important;
    }
}
