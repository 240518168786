.card {
    position: static;
}
// card tabs => form / working & delivery hours / classifications & advantages / analytics
@mixin tabs ($color, $padding) {
    box-shadow: none !important;
    border: 0;
    border-radius: 0;
    .nav-pills {
        background-color: rgba(90, 71, 206, 0.09);
        .nav-link {
            border-radius: 0 !important;
            padding-top: $padding;
            padding-bottom: $padding;
            display: block;
            color: #5a47ce;
            &:hover {
                background-color: $color;
                color: #fff;
            }
        }
        .active {
            color: #fff;
            background-color: $color !important;
        }
    }
    .tab-content {
        padding: 1.25rem; // top right bottom left
    }
    .card-body {
        min-height: 250px;
    }
}
.small-tabs {
    @include tabs(#5a47ce, 1.2rem);
}
.offer-tabs{
    @include tabs(#5a47ce87, 2rem);
}
// card tabs for msgs & blocked items
.large-tabs {
    box-shadow: none !important;
    border: 0;
    border-radius: 0;
    tr {
        cursor: pointer;
    }
    .card {
        border: none;
        .tab-content {
            padding: 0 !important;
        }
    }
    .card-header {
        height: auto;
        padding: 0;
        border-bottom: none;
        button {
            font-size: 16px;
        }
        .btn-outline-danger {
            border-radius: 0;
            color: #5a47ce;
            border-color: #5a47ce;
            &:hover {
                color: #fff;
                background-color: #5a47ce87;
                border-color: #5a47ce87;
            }
        }
        .btn-outline-danger:not(:disabled):not(.disabled):active,
        .btn-outline-danger:not(:disabled):not(.disabled).active,
        .show > .btn-outline-danger.dropdown-toggle {
            color: #fff;
            background-color: #5a47ce87;
            border-color: #5a47ce87;
            padding: 2rem !important;
            border-radius: 0;
            box-shadow: none !important;
        }
    }
}

