// reviews tabs
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
    margin-bottom: 1rem;
}
.ant-progress-inner {
    border-radius: 0px !important;
}

// total
.total {
    .ant-card-body {
        width: 100%;
    }
    .total-text {
        font-size: 16px;
        font-weight: bold;
    }
    .notification-num {
        font-weight: bold;
        color: #3ac47d;
    }
    .reports-num {
        font-weight: bold;
        color: #f7b924;
    }
    .earnings-num {
        font-weight: bold;
        color: #d92550;
    }
}

.ant-card-head {
    background: #83588a;
    color: #fff;
}
.statics-icon,
.eye-icon {
    font-size: 35px;
    color: #6c757d;
}
.statics-icon:hover {
    color: #5a47ce;
    transition: all 0.13s ease-in-out;
}
.eye-icon {
    font-size: 20px !important;
}
.statics-num {
    color: #444054;
    margin-top: 1rem;
    font-size: 20px;
    font-weight: bold;
}
.place-cat {
    margin-right: -24px;
    margin-left: -24px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.main-card {
    height: 460px;
}
.inner-card {
    .ant-card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
        p {
            margin-bottom: 0;
        }
    }
}

.inner-card:hover {
    color: #fff;
    background-color: #444054;
    border-color: #444054;
    transition: all 0.13s ease-in-out;
    h6 {
        color: #fff;
    }
}
.analytics{
    .restaurant-name{
        text-align: center;
        margin-top: 1rem;
    }
    .views{
        margin-bottom: 2rem;
    }
}
@media only screen and (min-width: 768px) {
    .analytics{
        .restaurant-name{
            text-align: start;
            padding: 0 !important;
            margin-top: 0;
        }
        .views{
            margin-bottom: 0;
        }
    }
}