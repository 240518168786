// dropdown
.ant-dropdown {
    min-width: 280px !important;
    .ant-checkbox-group-item {
        display: block !important;
    }
    // selectbox
    .ant-select-selector {
        border-radius: 6px !important;
        background-color: #f5f5f5 !important;
        border-color: transparent !important;
        &:hover,
        &:focus,
        &:not(:focus) {
            border-color: none !important;
            box-shadow: 0 0 0 0 transparent !important;
        }
    }
    .ant-select-rtl.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        height: 46px !important;
        display: flex;
        align-items: center;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input {
        border-radius: 6px !important;
        background-color: #f5f5f5 !important;
        border-color: none !important;
        &:focus,
        &:hover,
        &:not(:focus) .ant-input-focused {
            background-color: #f5f5f5 !important;
            border-color: none !important;
            outline: 0 !important;
            box-shadow: 0 0 0 0 transparent !important;
        }
    }

    .ant-input-affix-wrapper > input.ant-input,
    .ant-input-affix-wrapper {
        background-color: #f5f5f5 !important;
        border-color: transparent !important;
        &:focus,
        .ant-input-affix-wrapper-focused,
        &:hover {
            background-color: #f5f5f5 !important;
            border-color: transparent !important;
            border-width: 0;
            outline: 0;
            box-shadow: 0 0 0 0 transparent;
        }
    }
}

// search section

.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button,
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    border-radius: 90px 0 0 90px !important;
    border: none !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
}
.ant-input-search-button:not(.ant-btn-primary) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-section {
    background-color: #f5f5f5;
    margin-bottom: 24px;
    .ant-input {
        font-weight: bold;
    }
    .ant-input-group-rtl {
        direction: ltr !important;
        background-color: #fff;
        border-radius: 90px;
        color: #8e8e8e !important;
        font-weight: bold !important;
    }
    .ant-input-group-rtl.ant-input-group
        .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 90px 0 0 90px !important; // top right bottom left
        border: none;
    }
    .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button,
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        border-radius: 0 90px 90px 0 !important;
        border: none;
    }
    .anticon-search {
        transform: rotate(90deg);
    }
    // filter
    a:hover {
        text-decoration: none !important;
    }
    .ant-dropdown-trigger {
        color: #707070;
    }
}

// filter
.services-treeselect {
    .ant-select-selection-search-input {
        height: 36px !important;
    }
}
/* filter dropdown */
.ant-dropdown {
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.16);
    li,
    a {
        color: #666666 !important;
    }
}

// checkbox
.ant-checkbox-wrapper {
    span {
        font-size: 16px !important;
        color: #666666 !important;
    }
}
.ant-checkbox {
    border: 1px solid #dddddd;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7868d4;
    border-color: #7868d4;
}
.ant-form input[type='checkbox']:focus {
    outline: none !important;
}
.ant-checkbox-checked::after {
    border-color: #7868d4 !important;
}

//disabled checkbox
.ant-checkbox-disabled {
    color: #00000040;
    .ant-checkbox-inner {
        background-color: #dddddd;
        border-color: #dddddd;
    }
}

div[disabled]{
    .ant-select-selection-item {
        display: none !important;
    }
}
/* media query */
@media only screen and (min-width: 768.1px) {
    .ant-input-group-wrapper {
        width: 600px !important;
    }
}
