// Page title
.page-title-wrapper{
    color: #707070;
    font-size: 16px;
    font-weight: bold;
    p{
        margin: 0;
    }
    background-color: #E4E4E4 ;
    padding: 1rem;
}

/* responsive */
@media only screen and (max-width: 768px) {
    .page-title-wrapper {
        .title-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .page-title-heading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (min-width: 768.1px) {
    .page-title-heading{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
